<!-- 暂时弃用,使用对话框打开考生详情信息 -->
<template>
  <div id="app-container">
    <el-row>
      <el-col :span="8">
        <el-card class="box-card" shadow="hover" v-loading="loading">
          <div>
            <img src alt />
            <p>
              <span>姓名</span>
              <span>{{ studentInfo.nickName }}</span>
            </p>
            <p>
              <span>学号</span>
              <span>{{ studentInfo.studentCode }}</span>
            </p>
            <p>
              <span>学校</span>
              <span>{{ studentInfo.sysOrgSchoolName }}</span>
            </p>
            <p>
              <span>毕业年份</span>
              <span>{{ studentInfo.graduationYear }}</span>
            </p>
            <p>
              <span>班级</span>
              <span>{{ studentInfo.sysOrgSchoolClassName }}</span>
            </p>
            <p>
              <span>性别</span>
              <span>
                <el-radio v-model="studentInfo.gender" label="1">男</el-radio>
                <el-radio v-model="studentInfo.gender" label="0">女</el-radio>
              </span>
            </p>
            <p>
              <span>证件类型</span>
              <span v-if="studentInfo.idCardType == 1">身份证</span>
              <span v-if="studentInfo.idCardType == 2"
                >港澳居民往来内地通行证</span
              >
              <span v-if="studentInfo.idCardType == 3"
                >台湾居民往来内地通行证</span
              >
              <span v-if="studentInfo.idCardType === 4">护照</span>
            </p>
            <p>
              <span>证件号码</span>
              <span>{{ studentInfo.idCard }}</span>
            </p>
            <p style="margin: 20px 0 0 92px">
              <el-button
                v-throttle
                type="primary"
                icon
                size="medium"
                @click="confirm"
                >确认</el-button
              >
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getStudentApi } from '@/api/user/studentManage.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'studentDetails',
  data() {
    return {
      loading: false,
      studentId: 0,
      studentInfo: {},
    }
  },
  mounted() {
    this.studentId = this.$route.params.id
    this.getStudent()
  },
  methods: {
    getStudent() {
      if (isEmpty(this.studentId)) {
        return
      }
      this.loading = true
      getStudentApi(this.studentId)
        .then((res) => {
          this.loading = false
          if (res.success) {
            if (isEmpty(res.data)) {
              return
            }
            this.studentInfo = {}
            this.studentInfo = res.data
            this.studentInfo.gender = this.studentInfo.gender + ''
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    confirm() {
      //置空防止数据污染
      this.studentId = 0
      this.studentInfo = {}
      this.$router.push({ name: 'studentManage' })
    },
  },
}
</script>
<style>
.box-card {
  margin-top: 20px;
}

#app-container img {
  width: 137px;
  height: 137px;
  margin-left: 92px;
}

.box-card > .el-card__body > div > p > span:first-child {
  font: normal bolder 15px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #303133;
  display: inline-block;
  width: 65px;
  text-align: right;
}

.box-card > .el-card__body > div > p > span:last-child {
  font: normal normal 15px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #999;
  margin-left: 25px;
}
</style>
